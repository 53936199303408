import { ETFInfoButton } from '@cfra-nextgen-frontend/shared';
import { tooltipThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle35,
    TypographyStyle36
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box } from '@mui/material';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';

export function InformationIcon() {
    return (
        <ETFInfoButton
            tooltipTheme={tooltipThemeV3}
            tooltipText={
                <>
                    <Box sx={{ paddingBottom: '8px', width: '100%' }}>
                        <TypographyStyle36 display='inline'>Biggest Concerns</TypographyStyle36>
                        <BadgeBiggestConcerns
                            tooltipTitle=''
                            containerSx={{ display: 'inline-block', padding: '0px 8px' }}
                        />
                        <TypographyStyle35 display='inline'>
                            Portfolio of our highest conviction companies with substantial risk of deteriorating
                            earnings quality or fraud.
                        </TypographyStyle35>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <TypographyStyle36 display='inline'>Hazard List</TypographyStyle36>
                        <BadgeHazardList
                            tooltipTitle=''
                            containerSx={{ display: 'inline-block', padding: '0px 8px' }}
                        />
                        <TypographyStyle35 display='inline'>
                            Companies with significant accounting or governance risks, warranting extreme caution for
                            investors, especially in long positions.
                        </TypographyStyle35>
                    </Box>
                </>
            }
            buttonFontSize={20}
        />
    );
}