import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { getFullRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { rest } from 'msw';
import biggestConcernsCurrentScreenerData from './json/biggestConcernsCurrentScreenerData.json';
import industryResearchScreenerData from './json/industryResearchScreenerData.json';
import researchHubFiltersData from './json/researchHubFiltersData.json';
import researchHubScreenerData from './json/researchHubScreenerData.json';
import researchPdfScreenerData from './json/researchPdfScreenerData.json';
import scoresScreenerData from './json/scoresScreenerData.json';
import watchlistScreenerData from './json/watchlistScreenerData.json';
import homeIndustryResearch from './json/homeIndustryResearch.json';
import homeRiskMonitor from './json/homeRiskMonitor.json';

const getFullInstitutionalRequestQuery = (requestQuery: string) =>
    getFullRequestQuery({ requestQuery: requestQuery, apiName: ApiNames.Research });

export const handlersForTests = [
    // Create Handler for filters
    rest.get(`${BASE_API_URL}${getFullInstitutionalRequestQuery('research/screener-filters')}`, (req, res, ctx) => {
        return res(ctx.json(researchHubFiltersData));
    }),

    // Create Handler for filters
    rest.post(`${BASE_API_URL}${getFullInstitutionalRequestQuery('research/screener-filters')}`, (req, res, ctx) => {
        return res(ctx.json(researchHubFiltersData));
    }),

    // Create Handler for research screener
    rest.get(`${BASE_API_URL}${getFullInstitutionalRequestQuery('research/screener')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');

        switch (view) {
            case 'pdf':
                return res(ctx.json(researchPdfScreenerData));
            case 'industry_headlines':
                return res(ctx.json(industryResearchScreenerData));
            case 'research_hub':
                return res(ctx.json(researchHubScreenerData));
            case 'industry_research':
                return res(ctx.json(homeIndustryResearch));
        }

        return res(ctx.json(researchHubScreenerData));
    }),

    // Create Handler for research screener
    rest.post(`${BASE_API_URL}${getFullInstitutionalRequestQuery('research/screener')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');

        switch (view) {
            case 'pdf':
                return res(ctx.json(researchPdfScreenerData));
            case 'industry_headlines':
                return res(ctx.json(industryResearchScreenerData));
            case 'research_hub':
                return res(ctx.json(researchHubScreenerData));
            case 'industry_research':
                return res(ctx.json(homeIndustryResearch));
        }

        return res(ctx.json(researchHubScreenerData));
    }),

    // Create Handler for company screener
    rest.get(`${BASE_API_URL}${getFullInstitutionalRequestQuery('company/screener')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');

        switch (view) {
            case 'biggest_concerns_full':
                return res(ctx.json(biggestConcernsCurrentScreenerData));
            case 'watchlist':
                return res(ctx.json(watchlistScreenerData));
            case 'scores':
                return res(ctx.json(scoresScreenerData));
            case 'risk_monitor':
                return res(ctx.json(homeRiskMonitor));
        }
    }),

    // Create Handler for company screener
    rest.post(`${BASE_API_URL}${getFullInstitutionalRequestQuery('company/screener')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');

        switch (view) {
            case 'biggest_concerns_full':
                return res(ctx.json(biggestConcernsCurrentScreenerData));
            case 'watchlist':
                return res(ctx.json(watchlistScreenerData));
            case 'scores':
                return res(ctx.json(scoresScreenerData));
            case 'risk_monitor':
                return res(ctx.json(homeRiskMonitor));
        }
    }),
];

export const handlersForMSW = handlersForTests;
