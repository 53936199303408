import { Layout } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as eyeIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/eye.svg';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { CommonButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/CommonButton';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { TypographyStyle5 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { getUserColumnDefs } from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { AgGridPreferencesEachElement, PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import {
    ApiNames,
    getDateTimeUtcNow,
    LookupItems,
    RegionId,
    RequestTypes,
    ResearchTypeId,
    standardDateFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, createTheme, Grid, Stack, SxProps, ThemeProvider } from '@mui/material';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import { WatchlistCompanyIds } from 'components/Watchlist/ManageWatchlist';
import { InformationIcon } from 'features/accountingLens/components/RiskMonitor/InformationIcon';
import moment from 'moment';
import { useCallback, useContext, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
const size = 25;
const sendSingleRequestConfig = {
    path: 'company/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'researchCompanyScreenerQuery',
};

export const RiskMonitorContainerStyles: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    padding: '8px 16px 15px 16px',
    height: '100%',
    width: '100%',
};

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

const preferencesConfiguration = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.HomePageRiskMonitor],
    },
};

export const gridLoadingContainerStyles: SxProps = { margin: '48px 0', width: '100%', textAlign: 'center' };

const cfraSecurityTradingIdField = 'company_security.security_trading.cfra_security_trading_id';
const cfraCompanyIdField = 'company_security.company.cfra_company_id';

type RiskMonitorProps = {
    watchlistCompanyIds: WatchlistCompanyIds;
};

function ViewFullListButton({ disabled, onClickCallback }: { disabled?: boolean; onClickCallback?: () => void }) {
    const color = disabled ? '#00000042' : '#007AB9';

    return (
        <CommonButton
            disabled={disabled}
            theme={createTheme({})}
            content={
                <>
                    <CustomSvgIcon
                        component={eyeIcon}
                        viewBox='0 0 16 16'
                        sx={{
                            width: '17px',
                            height: '16px',
                            stroke: color,
                            marginRight: '6px',
                        }}
                    />
                    <TypographyStyle5 sx={{ textTransform: 'none', lineHeight: '18px', color }}>
                        View Full List
                    </TypographyStyle5>
                </>
            }
            onClickCallback={onClickCallback}
            outlined={true}
            sx={{ padding: '8px 9px', justifyContent: 'space-between', borderRadius: '6px' }}
        />
    );
}

function getRiskMonitorReqBody(watchlistFilter: any = {}) {
    const dateNow = getDateTimeUtcNow(standardDateFormat);
    const date2MonthAgo = moment.utc().subtract(2, 'months').format(standardDateFormat);

    return {
        filters: {
            values: {
                $and: [
                    {
                        frs_concerns_last_action_date: {
                            values: [date2MonthAgo, dateNow],
                        },
                    },
                    {
                        $or: [
                            {
                                'frs_hazard_list.is_biggest_concern': {
                                    values: [true, false],
                                },
                            },
                        ],
                    },
                    ...(Object.keys(watchlistFilter).length > 0 ? [watchlistFilter] : []),
                ],
            },
        },
    };
}

export function RiskMonitor({ watchlistCompanyIds }: RiskMonitorProps) {
    const { sendSingleRequest, getDataSource } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    if (!getDataSource) {
        throw new Error('getDataSource is not defined');
    }

    const { userEntitlements } = useUserEntitlements();

    const regionEntitlements: string[] = useMemo(() => {
        let _regionEntitlements: string[] = [];

        userEntitlements?.packages.forEach((packg) => {
            const companyReportEntitled = packg.entitlements?.some(
                (ent) =>
                    ent.item_lid === LookupItems.ResearchTypeId &&
                    Number(ent.value) === ResearchTypeId.FrsCompanyReport,
            );

            if (companyReportEntitled) {
                _regionEntitlements = _regionEntitlements.concat(
                    packg.entitlements
                        ?.filter(
                            (ent) =>
                                ent.item_lid === LookupItems.RegionLid &&
                                [RegionId.FRSAmericas, RegionId.FRSEurope].includes(Number(ent.value)),
                        )
                        .map((ent) => ent.value.toString()) || [],
                );
            }
        });

        return _regionEntitlements;
    }, [userEntitlements?.packages]);

    const watchlistFilter = useMemo(() => {
        const filter = [];
        const { cfraCompanyId, cfraSecurityTradingId } = watchlistCompanyIds;

        if (cfraCompanyId && cfraSecurityTradingId) {
            if (cfraSecurityTradingId.length > 0) {
                filter.push({ [cfraSecurityTradingIdField]: { values: cfraSecurityTradingId } });
            }

            if (cfraCompanyId.length > 0) {
                filter.push({ [cfraCompanyIdField]: { values: cfraCompanyId } });
            }

            if (cfraSecurityTradingId.length > 0 || cfraCompanyId.length > 0) {
                return { $or: filter };
            }
            return {};
        }
    }, [watchlistCompanyIds]);

    const navigate = useNavigate();
    const handleOnViewAllClick = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    const header = useMemo(() => {
        return (
            <CardHeaderVariant1
                title='Risk Monitor'
                containerStyles={{
                    paddingBottom: '14px',
                }}
                titlePostfixSlot={
                    <>
                        <BadgeBiggestConcerns tooltipTitle='' containerSx={{ paddingLeft: '10px' }} />
                        <BadgeHazardList tooltipTitle='' />
                        <InformationIcon />
                    </>
                }
                slot3={
                    regionEntitlements.length === 0 ? (
                        <ViewFullListButton disabled />
                    ) : (
                        <ViewFullListButton
                            onClickCallback={() => handleOnViewAllClick('/accounting-lens/risk-monitor')}
                        />
                    )
                }
            />
        );
    }, [handleOnViewAllClick, regionEntitlements]);

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfiguration?.useUserPreferencesProps,
    );

    const screenerCommonSearchByParams = useMemo(() => {
        return {
            securityType: 'research',
            view: 'risk_monitor',
            orderBy: 'frs_concerns_last_action_date',
            sortDirection: 'desc' as const,
            requestBody: getRiskMonitorReqBody(watchlistFilter),
        };
    }, [watchlistFilter]);

    const searchByParams = useMemo(() => {
        return {
            ...screenerCommonSearchByParams,
            size,
            config: {
                enabled: regionEntitlements.length > 0,
            },
        };
    }, [screenerCommonSearchByParams, regionEntitlements]);

    const companiesQuery = sendSingleRequest(
        searchByParams,
        sendSingleRequestConfig,
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!companiesQuery.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        let result = extractFromScreenerData({
            screenerData: companiesQuery.data,
            cardName: 'Risk Monitor',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });

        const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfiguration.selectorConfiguration.selector,
        });

        if (!userPreferences) {
            return result;
        }

        return {
            ...result,
            columnDefs: getUserColumnDefs({
                initialColumnDefs: result.columnDefs,
                userPreferences,
            }),
        };
    }, [companiesQuery.data, getUserPreferences]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const ssrDataSource = useMemo(() => {
        return getDataSource({
            metadataFields: companiesQuery?.data?._metadata.fields || [],
            etfData: companiesQuery?.data?.results?.company || [],
            requestParams: {
                path: 'company/screener',
                ...screenerCommonSearchByParams,
            },
            _resultsKey: 'company',
            size,
        });
    }, [
        companiesQuery?.data?._metadata.fields,
        companiesQuery?.data?.results?.company,
        getDataSource,
        screenerCommonSearchByParams,
    ]);

    const TableView = useMemo(() => {
        return (
            <AgGridCard
                maxNumberOfRowsToDisplay={5}
                useSSRMode
                getRowID={getRowID}
                SSRrowsToFetch={size}
                embedFullWidthRows
                columnDefs={columnDefs}
                gridTheme={gridTheme}
                SSRDataSource={ssrDataSource}
                showDefaultExportButton={false}
                customFlexibleColumns={customFlexibleColumns}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                labelProps={{ width: '100%' }}
                labelPanelContainerStyles={{ paddingTop: '36px' }}
                rowMultiSelectWithClick
                useDragScroll
                onColumnMovedGetter={keepNoLeftPaddingOnMove}
                onColumnVisibleGetter={keepNoLeftPaddingOnMove}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
                showSideHorizontalScrollIndicators
                autosizeColumnsConfig={{
                    skipHeader: false,
                    skipHasPinnedColumnsCheck: true,
                }}
                suppressHeaderMenuButton={false}
                preferencesConfiguration={preferencesConfiguration}
                enableSavingUserSortModel
                enableSavingUserColumnsOrder
                enableSavingUserColumnsVisibility
                enableSavingUserColumnsWidths
                useDisableColumnFlexOnResize
                useColumnWidthsFromColumnDefs
                setUserPreferencesOnReset={setUserPreferences}
            />
        );
    }, [columnDefs, customFlexibleColumns, getResizableMinWidthForColumn, setUserPreferences, ssrDataSource]);

    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={RiskMonitorContainerStyles}>
                <Grid container>{header}</Grid>

                {!companiesQuery?.isFetched && regionEntitlements.length > 0 && (
                    <Box sx={gridLoadingContainerStyles}>
                        <Layout.Skeleton height='10px' />
                    </Box>
                )}

                {(companiesQuery?.isFetched && (companiesQuery?.data?.results?.company || []).length === 0) ||
                regionEntitlements.length === 0 ? (
                    <Box sx={gridLoadingContainerStyles}>
                        <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                    </Box>
                ) : (
                    TableView
                )}
            </Stack>
        </ThemeProvider>
    );
}
