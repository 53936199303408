import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';

export enum RoutePaths {
    Home = '',
    AccountingLens = '/accounting-lens',
    Bespoke = '/bespoke',
    BespokeAdmin = '/bespoke/admin',
    AccountingLensBiggestConcern = '/accounting-lens/biggest-concern',
    AccountingLensCompanyResearch = '/accounting-lens/reports/company',
    AccountingLensIndustryResearch = '/accounting-lens/reports/industry',
    ResearchHub = '/research-hub',
    Scores = '/scores',
    BespokeEdge = '/bespoke/edge',
    BespokeServices = '/bespoke/services',
    LegalEdge = '/legal-edge',
    CompanyProfile = '/company-profile',
    Watchlists = '/watchlist',
    ContactUs = '/contact-us',
    AccountingLensAnalystDirectory = '/accounting-lens/analyst-directory',
    LegalEdgeAnalystDirectory = '/legal-edge/analyst-directory'
}

export enum PageLid {
    ResearchHub = 1,
    CompanyProfile = 2,
    PDFViewer = 3,
    Home = 4,
    AccountingLensBiggestConcern = 5,
    AccountingLensCompanyResearch = 6,
    AccountingLensIndustryResearch = 7,
    Scores = 8,
    LegalEdge = 9,
    BespokeServices = 10,
    BespokeEdge = 11,
    BespokeAdmin = 12,
    Watchlists = 13,
    ContactUs = 14,
}

export enum PageNames {
    Home = 'Home',
    AccountingLens = 'Accounting Lens',
    Bespoke = 'Bespoke',
    BespokeAdmin = 'Bespoke Admin',
    AccountingLensBiggestConcern = 'Biggest Concern',
    AccountingLensCompanyResearch = 'Company Research',
    AccountingLensIndustryResearch = 'Industry & Education Reports, IRAPs',
    ResearchHub = 'Research Hub',
    LegalEdge = 'Legal Edge',
    BespokeEdge = 'Bespoke Edge',
    BespokeServices = 'Bespoke Services',
    Scores = 'Scores',
    CompanyProfile = 'Company Profile',
    Watchlists = 'Watchlists',
    ContactUs = 'Contact Us',
    AccountingLensAnalystDirectory = 'Accounting Lens Analyst Directory',
    LegalEdgeAnalystDirectory = 'Legal Edge Analyst Directory'
}

export const PageNamesToRoutePaths: Record<PageNames, RoutePaths> = {
    [PageNames.Home]: RoutePaths.Home,
    [PageNames.AccountingLens]: RoutePaths.AccountingLens,
    [PageNames.Bespoke]: RoutePaths.Bespoke,
    [PageNames.BespokeAdmin]: RoutePaths.BespokeAdmin,
    [PageNames.AccountingLensBiggestConcern]: RoutePaths.AccountingLensBiggestConcern,
    [PageNames.AccountingLensCompanyResearch]: RoutePaths.AccountingLensCompanyResearch,
    [PageNames.AccountingLensIndustryResearch]: RoutePaths.AccountingLensIndustryResearch,
    [PageNames.ResearchHub]: RoutePaths.ResearchHub,
    [PageNames.Scores]: RoutePaths.Scores,
    [PageNames.LegalEdge]: RoutePaths.LegalEdge,
    [PageNames.BespokeEdge]: RoutePaths.BespokeEdge,
    [PageNames.BespokeServices]: RoutePaths.BespokeServices,
    [PageNames.CompanyProfile]: RoutePaths.CompanyProfile,
    [PageNames.Watchlists]: RoutePaths.Watchlists,
    [PageNames.ContactUs]: RoutePaths.ContactUs,
    [PageNames.AccountingLensAnalystDirectory]: RoutePaths.AccountingLensAnalystDirectory,
    [PageNames.LegalEdgeAnalystDirectory]: RoutePaths.LegalEdgeAnalystDirectory
};

export const InstitutionalResearchReportTypeIds = [
    ResearchTypeId.FrsBanksOrFIReport,
    ResearchTypeId.FrsBespokeAdminViewer,
    ResearchTypeId.FrsBespokeEdge,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsDailySnapshot,
    ResearchTypeId.FrsDiagnosticReport,
    ResearchTypeId.FrsEarningsAlert,
    ResearchTypeId.FrsEducationalReport,
    ResearchTypeId.FrsIndustryReport,
    ResearchTypeId.FrsIRAPS,
    ResearchTypeId.FrsNotebooks,
    ResearchTypeId.LegalAlert,
    ResearchTypeId.LegalEducational,
    ResearchTypeId.LegalPipeline,
    ResearchTypeId.LegalPipelineSpecialInterestList,
    ResearchTypeId.LegalReport,
    ResearchTypeId.PolicyNewsletterToWealth,
];

export const enum ResearchReportTypes {
    SpecialInterest = 28,
}

export enum SortOptions {
    BestMatch = 'Best Match',
    PublishDateAsc = 'Ascending',
    PublishDateDesc = 'Descending',
}

export enum ConcernActions {
    Added = 'Added',
    Removed = 'Removed',
}